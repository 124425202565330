import * as _ from "lodash";
import "./style.css";

const body = document.querySelector("body");
body.classList.add("body");

const cat = document.createElement("div");
cat.classList.add("cat");

body.appendChild(cat);
document.body.classList.add("no-margin-padding");

const floor = document.createElement("div");
floor.classList.add("floor");
document.body.appendChild(floor);

let initialLeft = (window.innerWidth - cat.offsetWidth) * 0.1;
let initialTop = window.innerHeight - cat.offsetHeight - floor.offsetHeight;

cat.style.left = `${initialLeft}px`;
cat.style.top = `${initialTop}px`;

let topRising = false;
let leftSpeed = 0;
let topSpeed = 0;

const GRAVITY = 0.8;
const JUMP_STRENGTH = 15;
const MOVE_SPEED = 7;

let upPressed = false;
let leftPressed = false;
let rightPressed = false;

document.addEventListener("keydown", (event) => {
    switch (event.key) {
        case "ArrowLeft":
            leftPressed = true;
            break;
        case "ArrowRight":
            rightPressed = true;
            break;
        case "ArrowUp":
            if (parseInt(cat.style.top) === initialTop) {
                upPressed = true;
            }
            break;
        default:
            break;
    }
});

document.addEventListener("keyup", (event) => {
    switch (event.key) {
        case "ArrowLeft":
            leftPressed = false;
            leftSpeed = 0;  // Reset horizontal speed when key is released
            break;
        case "ArrowRight":
            rightPressed = false;
            leftSpeed = 0;  // Reset horizontal speed when key is released
            break;
        case "ArrowUp":
            upPressed = false;
            break;
        default:
            break;
    }
});

const update = () => {
    let left = parseInt(cat.style.left) || 0;
    let top = parseInt(cat.style.top) || 0;

    if (upPressed && top === initialTop && !topRising) {
        topRising = true;
        topSpeed -= JUMP_STRENGTH;
    }

    if (leftPressed) {
        leftSpeed = -MOVE_SPEED;
    }

    if (rightPressed) {
        leftSpeed = MOVE_SPEED;
    }

    top += topSpeed;
    topSpeed += GRAVITY;

    if (top > initialTop) {
        top = initialTop;
        topSpeed = 0;
        topRising = false;
    }

    left += leftSpeed;

    if (left < 0) {
        left = 0;
        leftSpeed = 0;
    } else if (left > window.innerWidth - cat.offsetWidth) {
        left = window.innerWidth - cat.offsetWidth;
        leftSpeed = 0;
    }

    cat.style.left = `${left}px`;
    cat.style.top = `${top}px`;

    requestAnimationFrame(update);
};

requestAnimationFrame(update);

